import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig';
import WelcomeRoute from './welcomeRoute';

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover='content' />}>
      <Switch>
        <WelcomeRoute
          path={`${APP_PREFIX_PATH}/iqs`}
          component={lazy(() => import(`./iqs/index`))}
        />

        <WelcomeRoute
          path={`${APP_PREFIX_PATH}/review`}
          component={lazy(() => import(`./review`))}
        />

        <WelcomeRoute
          path={`${APP_PREFIX_PATH}/user-list`}
          component={lazy(() => import(`./user-list`))}
        />

        <WelcomeRoute
          path={`${APP_PREFIX_PATH}/practices`}
          component={lazy(() => import(`./practices`))}
        />

        <WelcomeRoute
          path={`${APP_PREFIX_PATH}/settings`}
          component={lazy(() => import(`./setting`))}
        />

        <WelcomeRoute
          path={`${APP_PREFIX_PATH}/pricing`}
          component={lazy(() => import(`./pricing`))}
        />

        <WelcomeRoute
          path={`${APP_PREFIX_PATH}/chat`}
          component={lazy(() => import(`./chat`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/welcome`}
          component={lazy(() => import(`./firstLogin`))}
        />

        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/404`} />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
