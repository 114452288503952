import {
  LOCAL_SMS,
  SET_SMS_COUNTER,
  UPDATE_SMS_COUNTER,
} from '../constants/SmsCounter';

const initState = {
  counter: localStorage.getItem(LOCAL_SMS),
};

const SmsCounter = (state = initState, action) => {
  switch (action.type) {
    case UPDATE_SMS_COUNTER:
      const updatedCounter =
        state.counter > 0 ? state.counter - action.count : state.counter;
      return { ...state, counter: updatedCounter };
    case SET_SMS_COUNTER:
      return { ...state, counter: action.payload };
    default:
      return state;
  }
};

export default SmsCounter;
