import {
  DashboardOutlined,
  ContactsOutlined,
  UnorderedListOutlined,
  PlusSquareOutlined,
  MessageOutlined,
  BulbOutlined,
  FundViewOutlined,
  UserOutlined,
  DollarOutlined,
  SettingOutlined,
  EnvironmentOutlined,
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig';

const sentientiqNavTree = [
  {
    key: 'sentientiq',
    path: `${APP_PREFIX_PATH}/sentientiq`,
    title: 'sidenav.sentientiq',
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'sentientiq-iqs',
        path: `${APP_PREFIX_PATH}/iqs`,
        title: 'sidenav.sentientiq.iqs',
        icon: BulbOutlined,
        breadcrumb: false,
        submenu: [],
      },

      {
        key: 'sentientiq-chat',
        path: `${APP_PREFIX_PATH}/chat`,
        title: 'sidenav.sentientiq.chat',
        icon: MessageOutlined,
        breadcrumb: false,
        submenu: [],
      },

      {
        key: 'sentientiq-review',
        path: `${APP_PREFIX_PATH}/review`,
        title: 'sidenav.sentientiq.review',
        icon: FundViewOutlined,
        breadcrumb: false,
        submenu: [],
      },

      {
        key: 'sentientiq-settings',
        path: `${APP_PREFIX_PATH}/settings`,
        title: 'sidenav.sentientiq.settings',
        icon: SettingOutlined,
        breadcrumb: false,
        submenu: [],
      },

      // {
      //   key: 'sentientiq-contacts',
      //   path: `${APP_PREFIX_PATH}/contacts`,
      //   title: 'sidenav.sentientiq.contacts',
      //   icon: ContactsOutlined,
      //   breadcrumb: false,
      //   submenu: [
      //     {
      //       key: 'sentientiq-contacts-list',
      //       path: `${APP_PREFIX_PATH}/contacts/list`,
      //       title: 'sidenav.sentientiq.contacts.list',
      //       icon: UnorderedListOutlined,
      //       breadcrumb: false,
      //       submenu: [],
      //     },
      //     {
      //       key: 'sentientiq-contacts-add',
      //       path: `${APP_PREFIX_PATH}/contacts/add`,
      //       title: 'sidenav.sentientiq.contacts.add',
      //       icon: PlusSquareOutlined,
      //       breadcrumb: false,
      //       submenu: [],
      //     },
      //   ],
      // },
      {
        key: 'sentientiq-users',
        path: `${APP_PREFIX_PATH}/user-list`,
        title: 'sidenav.sentientiq.user.list',
        icon: UserOutlined,
        breadcrumb: false,
        submenu: [],
      },
      // {
      //   key: 'sentientiq-pricing',
      //   path: `${APP_PREFIX_PATH}/pricing`,
      //   title: 'sidenav.sentientiq.pricing',
      //   icon: DollarOutlined,
      //   breadcrumb: false,
      //   submenu: [],
      // },
    ],
  },
];

const navigationConfig = [...sentientiqNavTree];

export default navigationConfig;
