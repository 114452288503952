import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Select } from 'antd';
import { USER_LOCATION } from 'redux/constants/Location';
import { setLocation } from 'redux/actions/Location';

const { Option } = Select;

export const SelectLocation = (props) => {
  const { setLocation } = props;
  let locations = useSelector((state) => state.auth.loggedInUser);
  locations = locations ? JSON.parse(locations).finalUser.locations : [];

  const locationDefault = locations ? locations[0].locationId : '';

  const [loading, setLoading] = useState(false);
  const [defaultValue, setDefaultValue] = useState(locationDefault);

  useEffect(() => {
    setLoading(true);
    let savedLocation = localStorage.getItem(USER_LOCATION);

    if (savedLocation) {
      savedLocation = JSON.parse(savedLocation);
      setDefaultValue(savedLocation.locationId);
    } else {
      const locationToLocal = locations ? JSON.stringify(locations[0]) : [];
      setLocation(locationToLocal);
      localStorage.setItem(USER_LOCATION, locationToLocal);
    }
    setLoading(false);
  }, []);

  const onChange = (value) => {
    setLoading(true);
    const newLocation = locations.filter(
      (locations) => locations.locationId === value
    );

    const newLoc = JSON.stringify(newLocation[0]);
    setLocation(newLoc);
    localStorage.setItem(USER_LOCATION, newLoc);
    setDefaultValue(value);

    setLoading(false);
  };

  return (
    <Select
      value={defaultValue}
      loading={loading}
      showSearch
      style={{
        width: 300,
        padding: 0,
        fontSize: 12,
        color: '#666666',
      }}
      placeholder='Select a Location'
      optionFilterProp='children'
      onChange={onChange}
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      filterSort={(optionA, optionB) =>
        optionA.children
          .toLowerCase()
          .localeCompare(optionB.children.toLowerCase())
      }>
      {locations &&
        locations.map((loc, index) => {
          const { locationId, locationName } = loc;
          return (
            <Option value={locationId} key={index}>
              {locationName}
            </Option>
          );
        })}
    </Select>
  );
};
const mapStateToProps = ({ location }) => {
  const { selectedLocation } = location;
  return { selectedLocation };
};

const mapDispatchToProps = {
  setLocation,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectLocation);
