import {
  HIDE_CLIENT_MESSAGE,
  SHOW_CLIENT_MESSAGE,
  SHOW_LOADING,
  SUCCESS_BUSINESS_DETAILS,
} from '../constants/Client';

export const showClientMessage = (message) => {
  return {
    type: SHOW_CLIENT_MESSAGE,
    message,
  };
};

export const hideClientMessage = () => {
  return {
    type: HIDE_CLIENT_MESSAGE,
  };
};

export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  };
};

export const successBusinessDetails = (user) => {
  return {
    type: SUCCESS_BUSINESS_DETAILS,
    user,
  };
};
