import fetch from 'auth/FetchInterceptor';

const JwtAuthService = {};

JwtAuthService.login = async function (data) {
  return await fetch({
    url: '/auth/login',
    method: 'post',
    headers: {
      'public-request': 'true',
      'Content-Type': 'application/json',
    },
    data: data,
  })
    .then((response) => response)
    .catch((err) => err);
};

JwtAuthService.signUp = function (data) {
  return fetch({
    url: '/auth/signup',
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: data,
  });
};

JwtAuthService.verifyUser = async function (data) {
  return await fetch({
    url: '/auth/verify-user',
    method: 'post',
    headers: {
      'public-request': 'true',
      'Content-Type': 'application/json',
    },
    data: data,
  })
    .then((response) => response)
    .catch((err) => err);
};

export default JwtAuthService;
