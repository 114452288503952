import { SET_LOCATION, UPDATE_USER_LOCATION } from '../constants/Location';

export const setLocation = (location) => {
  return {
    type: SET_LOCATION,
    payload: location,
  };
};

export const updateUserLocation = (location) => {
  return {
    type: UPDATE_USER_LOCATION,
    payload: location,
  };
};
