import React from 'react';
import { useSelector } from 'react-redux';
import { LOCAL_SMS } from 'redux/constants/SmsCounter';

export const NavSmsCounter = (props) => {
  const counter = useSelector((state) => state.smsCounter.counter);

  localStorage.setItem(LOCAL_SMS, counter);
  return <div>{`Available Credit: ${counter}`}</div>;
};

export default NavSmsCounter;
