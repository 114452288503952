import { UPDATE_SMS_COUNTER, SET_SMS_COUNTER } from '../constants/SmsCounter';

export const incCounter = (count) => ({
  type: UPDATE_SMS_COUNTER,
  count,
});

export const setCounter = (sms_data) => ({
  type: SET_SMS_COUNTER,
  payload: sms_data,
});
