import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
  AUTH_TOKEN,
  LOGGED_IN_USER,
  SIGNIN,
  VERIFY_USER,
  SIGNOUT,
  SIGNUP,
  SIGNIN_WITH_GOOGLE,
  SIGNIN_WITH_FACEBOOK,
} from '../constants/Auth';
import {
  showAuthMessage,
  authenticated,
  verifiedUser,
  signOutSuccess,
  signUpSuccess,
  signInWithGoogleAuthenticated,
  signInWithFacebookAuthenticated,
} from '../actions/Auth';
import { USER_LOCATION } from 'redux/constants/Location';

import FirebaseService from 'services/FirebaseService';
import JwtAuthService from 'services/JwtAuthService';
import { LOCAL_SMS } from 'redux/constants/SmsCounter';
import { setCounter } from 'redux/actions/SmsCounter';

export function* verifyUserWithCode() {
  yield takeEvery(VERIFY_USER, function* ({ payload }) {
    const { signupToken, verificationCode } = payload;
    try {
      const user = yield call(JwtAuthService.verifyUser, {
        signupToken,
        verificationCode,
      });
      if (user.message) {
        yield put(showAuthMessage(user.response.data.message));
      } else {
        localStorage.setItem(AUTH_TOKEN, user.finalUser.accessToken);
        localStorage.setItem(LOGGED_IN_USER, JSON.stringify(user));
        yield put(
          verifiedUser(user.finalUser.accessToken, JSON.stringify(user))
        );
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* signInWithEmail() {
  yield takeEvery(SIGNIN, function* ({ payload }) {
    const { username, password } = payload;
    try {
      const user = yield call(JwtAuthService.login, {
        username,
        password,
      });
      if (user.message) {
        yield put(showAuthMessage(user.response.data.message));
      } else {
        localStorage.setItem(LOCAL_SMS, user.finalUser.sms_counter);
        localStorage.setItem(AUTH_TOKEN, user.finalUser.accessToken);
        localStorage.setItem(LOGGED_IN_USER, JSON.stringify(user));
        yield put(
          authenticated(user.finalUser.accessToken, JSON.stringify(user))
        );
        yield put(setCounter(user.finalUser.sms_counter));
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* signUpWithEmail() {
  yield takeEvery(SIGNUP, function* ({ payload }) {
    const { first_name, last_name, username, email, password } = payload;
    try {
      const user = yield call(JwtAuthService.signUp, {
        first_name,
        last_name,
        username,
        email,
        password,
      });
      if (user.message) {
        yield put(showAuthMessage(user.response.data.message));
      } else {
        yield put(signUpSuccess(user.token));
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* signOut() {
  yield takeEvery(SIGNOUT, function* () {
    try {
      const signOutUser = yield call(FirebaseService.signOutRequest);
      if (signOutUser === undefined) {
        localStorage.removeItem(AUTH_TOKEN);
        localStorage.removeItem(LOGGED_IN_USER);
        localStorage.removeItem(USER_LOCATION);
        localStorage.removeItem(LOCAL_SMS);
        yield put(signOutSuccess(signOutUser));
      } else {
        yield put(showAuthMessage(signOutUser.message));
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* signInWithFBEmail() {
  yield takeEvery(SIGNIN, function* ({ payload }) {
    const { email, password } = payload;
    try {
      const user = yield call(
        FirebaseService.signInEmailRequest,
        email,
        password
      );
      if (user.message) {
        yield put(showAuthMessage(user.message));
      } else {
        localStorage.setItem(AUTH_TOKEN, user.user.uid);
        yield put(authenticated(user.user.uid));
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* signUpWithFBEmail() {
  yield takeEvery(SIGNUP, function* ({ payload }) {
    const { email, password } = payload;
    try {
      const user = yield call(
        FirebaseService.signUpEmailRequest,
        email,
        password
      );
      if (user.message) {
        yield put(showAuthMessage(user.message));
      } else {
        localStorage.setItem(AUTH_TOKEN, user.user.uid);
        yield put(signUpSuccess(user.user.uid));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  });
}

export function* signInWithFBGoogle() {
  yield takeEvery(SIGNIN_WITH_GOOGLE, function* () {
    try {
      const user = yield call(FirebaseService.signInGoogleRequest);
      if (user.message) {
        yield put(showAuthMessage(user.message));
      } else {
        localStorage.setItem(AUTH_TOKEN, user.user.uid);
        yield put(signInWithGoogleAuthenticated(user.user.uid));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  });
}

export function* signInWithFacebook() {
  yield takeEvery(SIGNIN_WITH_FACEBOOK, function* () {
    try {
      const user = yield call(FirebaseService.signInFacebookRequest);
      if (user.message) {
        yield put(showAuthMessage(user.message));
      } else {
        localStorage.setItem(AUTH_TOKEN, user.user.uid);
        yield put(signInWithFacebookAuthenticated(user.user.uid));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(signInWithEmail),
    fork(verifyUserWithCode),
    fork(signUpWithEmail),
    fork(signOut),
  ]);
}
