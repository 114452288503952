import React, { useEffect, useState } from 'react';
import { Menu, Dropdown, Avatar, message } from 'antd';
import { connect, useSelector } from 'react-redux';
import {
  ProfileOutlined,
  QuestionCircleOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import Icon from 'components/util-components/Icon';
import { signOut } from 'redux/actions/Auth';
import IdleTimer from 'IdleTimer';

const menuItem = [
  {
    title: 'Profile',
    icon: ProfileOutlined,
    path: '/profile',
  },

  {
    title: 'Help Center',
    icon: QuestionCircleOutlined,
    path: '/',
  },
];

export const NavProfile = ({ signOut }) => {
  let user = useSelector((state) => state.auth.loggedInUser);
  user = JSON.parse(user).finalUser;

  const [seconds, setSeconds] = useState(0);
  const [timer, setTimer] = useState(false);

  useEffect(() => {
    const timer = new IdleTimer({
      timeout: 600, //expire after 10 seconds
      onTimeout: () => {
        signOut();
      },
      onExpired: () => {
        //do something if expired on load
      },
      showTimer: (times) => {
        if (times > 10) {
          setTimer(false);
        }
        if (times >= 9 && times <= 10) {
          setSeconds(10);
          setTimer(true);
        }
      },
    });

    return () => {
      timer.cleanUp();
    };
  }, []);

  useEffect(() => {
    if (timer === true) {
      const hide = message.loading('You will be logout withing 10 seconds', 0);
      setTimeout(hide, seconds * 1000);
    } else {
      message.destroy();
    }
  }, [seconds, timer]);

  const profileImg = user.profileImg
    ? user.profileImg
    : '/img/avatars/profileImg.svg';
  const profileMenu = (
    <div className='nav-profile nav-dropdown'>
      <div className='nav-profile-header'>
        <div className='d-flex'>
          <Avatar size={45} src={profileImg} />
          <div className='pl-3'>
            <h4 className='mb-0'>
              {user.first_name} {user.last_name}
            </h4>
            <span className='text-muted'>{user.roles[0]}</span>
          </div>
        </div>
      </div>
      <div className='nav-profile-body'>
        <Menu>
          {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i}>
                <a href={el.path}>
                  <Icon className='mr-3' type={el.icon} />
                  <span className='font-weight-normal'>{el.title}</span>
                </a>
              </Menu.Item>
            );
          })}
          <Menu.Item key={menuItem.length + 1} onClick={(e) => signOut()}>
            <span>
              <LogoutOutlined className='mr-3' />
              <span className='font-weight-normal'>Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement='bottomRight' overlay={profileMenu} trigger={['click']}>
      <Menu className='d-flex align-item-center' mode='horizontal'>
        <Menu.Item>
          <Avatar src={profileImg} />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

export default connect(null, { signOut })(NavProfile);
