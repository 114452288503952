const dev = {
  API_ENDPOINT_URL: 'http://localhost:8000',
};

const prod = {
  API_ENDPOINT_URL: 'https://sentientiq.com:9002',
};

const test = {
  API_ENDPOINT_URL:
    'http://4e60-2405-201-4026-80ae-ece5-df16-c1b1-f90b.ngrok.io/api',
};

const getEnv = () => {
  switch (process.env.NODE_ENV) {
    case 'development':
      return dev;
    case 'production':
      return prod;
    case 'test':
      return test;
    default:
      break;
  }
};

export const env = getEnv();
