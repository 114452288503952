import {
  SET_LOCATION,
  USER_LOCATION,
  UPDATE_USER_LOCATION,
} from '../constants/Location';

const initState = {
  selectedLocation: localStorage.getItem(USER_LOCATION)
    ? localStorage.getItem(USER_LOCATION)
    : '',
};

const location = (state = initState, action) => {
  switch (action.type) {
    case SET_LOCATION:
      return {
        ...state,
        selectedLocation: action.payload,
      };

    case UPDATE_USER_LOCATION:
      return {
        ...state,
        selectedLocation: action.payload,
      };

    default:
      return state;
  }
};

export default location;
