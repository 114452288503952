import {
  SHOW_CLIENT_MESSAGE,
  HIDE_CLIENT_MESSAGE,
  SHOW_LOADING,
  SUCCESS_BUSINESS_DETAILS,
} from '../constants/Client';

import { LOGGED_IN_USER, AUTH_TOKEN } from '../constants/Auth';

const initState = {
  loading: false,
  message: '',
  showMessage: false,
  redirect: '',
  token: localStorage.getItem(AUTH_TOKEN),
  loggedInUser: localStorage.getItem(LOGGED_IN_USER),
};

const client = (state = initState, action) => {
  switch (action.type) {
    case SUCCESS_BUSINESS_DETAILS:
      return {
        ...state,
        loading: false,
        redirect: '/review',
        loggedInUser: action.user,
      };
    case SHOW_CLIENT_MESSAGE:
      return {
        ...state,
        message: action.message,
        showMessage: true,
        loading: false,
      };
    case HIDE_CLIENT_MESSAGE:
      return {
        ...state,
        message: '',
        showMessage: false,
      };

    case SHOW_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    default:
      return state;
  }
};

export default client;
