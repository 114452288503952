import { all, fork, takeEvery, call, put } from 'redux-saga/effects';

import { SAVE_BUSINESS_DETAILS } from '../constants/Client';
import { LOGGED_IN_USER } from '../constants/Auth';
import { successBusinessDetails, showClientMessage } from '../actions/Client';

import ClientService from 'services/ClientService';

export function* saveBusinessDetails() {
  yield takeEvery(SAVE_BUSINESS_DETAILS, function* ({ payload }) {
    const { username, email } = payload;
    try {
      const resp = yield call(ClientService.uniqueUserName, {
        username,
        email,
      });
      if (resp.message) {
      } else {
        const loggedInUser = JSON.parse(localStorage.getItem(LOGGED_IN_USER));
        loggedInUser.finalUser.status = 1;
        localStorage.setItem(LOGGED_IN_USER, JSON.stringify(loggedInUser));

        yield put(successBusinessDetails(JSON.stringify(loggedInUser)));
      }
    } catch (err) {
      yield put(showClientMessage(err));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(saveBusinessDetails)]);
}
