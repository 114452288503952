import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { SearchOutlined } from '@ant-design/icons';
import { AutoComplete, Input } from 'antd';

const SearchInput = (props) => {
  let userLocations = useSelector((state) => state.auth.loggedInUser);
  userLocations = JSON.parse(userLocations).finalUser.locations;

  const { active, close, isMobile, mode } = props;
  const [value, setValue] = useState('');
  const [options, setOptions] = useState([]);
  const inputRef = useRef(null);

  const onSelect = (value) => {
    setValue(value);
    if (close) {
      close();
    }
  };

  const searchResult = () =>
    userLocations.map((location) => {
      return {
        value: location.locationName,
        label: (
          <div className='search-list-item'>
            <div>
              <div className='font-weight-semibold'>
                {location.locationName}
              </div>
              <div className='font-size-sm text-muted'>
                {location?.textPhone}
              </div>
            </div>
          </div>
        ),
      };
    });

  const onSearch = (searchText) => {
    setValue(searchText);
    setOptions(!searchText ? [] : searchResult(searchText));
  };

  const autofocus = () => {
    inputRef.current.focus();
  };

  if (active) {
    autofocus();
  }

  return (
    <AutoComplete
      ref={inputRef}
      className={`nav-search-input ${isMobile ? 'is-mobile' : ''} ${
        mode === 'light' ? 'light' : ''
      }`}
      dropdownClassName='nav-search-dropdown'
      options={options}
      onSelect={onSelect}
      onSearch={onSearch}
      value={value}
      filterOption={(inputValue, option) =>
        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
      }>
      <Input
        placeholder='Select a Location'
        autoComplete='off'
        prefix={<SearchOutlined className='mr-0' />}
      />
    </AutoComplete>
  );
};

export default SearchInput;
