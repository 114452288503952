import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

const WelcomeRoute = ({ path, component, ...rest }) => {
  let currentUser = useSelector((state) => state.auth.loggedInUser);
  currentUser = JSON.parse(currentUser).finalUser;

  return (
    <Route
      {...rest}
      render={(props) =>
        currentUser.status === 2 ? (
          <Redirect
            to={{
              pathname: '/welcome',
            }}
          />
        ) : (
          <Route path={path} component={component} />
        )
      }
    />
  );
};

export default WelcomeRoute;
