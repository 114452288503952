import axios from 'axios';
import { API_BASE_URL } from 'configs/AppConfig';
import history from '../history';
import { AUTH_TOKEN, LOGGED_IN_USER } from 'redux/constants/Auth';
import { USER_LOCATION } from 'redux/constants/Location';
import { notification } from 'antd';
import { LOCAL_SMS } from 'redux/constants/SmsCounter';

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 0,
});

// Config
const ENTRY_ROUTE = '/auth/login';
const TOKEN_PAYLOAD_KEY = 'authorization';
const PUBLIC_REQUEST_KEY = 'public-request';
const LOCATION_ID = 'locationid';

// API Request interceptor
service.interceptors.request.use(
  (config) => {
    const jwtToken = localStorage.getItem(AUTH_TOKEN);
    const location = localStorage.getItem(USER_LOCATION)
      ? JSON.parse(localStorage.getItem(USER_LOCATION))
      : '';

    if (jwtToken) {
      config.headers[TOKEN_PAYLOAD_KEY] = `Bearer ${jwtToken}`;
      config.headers[LOCATION_ID] = location.locationId;
    }

    if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
      history.push(ENTRY_ROUTE);
      window.location.reload();
    }

    return config;
  },
  (error) => {
    // Do something with request error here

    notification.error({
      message: 'Error',
    });
    Promise.reject(error);
  }
);

// API respone interceptor
service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    let notificationParam = {
      message: '',
    };
    if (error.response.status === 205) {
      notificationParam.message = 'Need to reset!';
    }
    // Remove token and redirect
    if (error.response.status === 302) {
      notificationParam.message = 'Already exist';
    }
    if (error.response.status === 401) {
      notificationParam.message = 'Unauthorized';
      notificationParam.description = 'Please login again';
      // localStorage.removeItem(AUTH_TOKEN);
      // localStorage.removeItem(LOGGED_IN_USER);
      // localStorage.removeItem(USER_LOCATION);
      // localStorage.removeItem(LOCAL_SMS);
      // history.push(ENTRY_ROUTE);
      // window.location.reload();
    }

    if (error.response.status === 400 || error.response.status === 403) {
      notificationParam.message = 'Authentication Fail';
      notificationParam.description = 'Please login again';
      localStorage.removeItem(AUTH_TOKEN);
      localStorage.removeItem(LOGGED_IN_USER);
      localStorage.removeItem(USER_LOCATION);
      localStorage.removeItem(LOCAL_SMS);
      history.push(ENTRY_ROUTE);
      window.location.reload();
    }

    if (error.response.status === 404) {
      notificationParam.message = 'Not Found';
    }

    if (error.response.status === 409) {
      notificationParam.message = 'Conflict Error! ';
    }

    if (error.response.status === 500) {
      notificationParam.message = 'Internal Server Error';
    }

    if (error.response.status === 508) {
      notificationParam.message = 'Time Out';
    }

    if (error.response.status === 408) {
      notificationParam.message = 'Time Out';
    }

    if (error.response.status === 503) {
      notificationParam.message = 'Service Unavailable';
      history.push('/welcome');
      window.location.reload();
    }

    // notification.error(notificationParam);

    return Promise.reject(error);
  }
);

export default service;
