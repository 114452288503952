import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig';

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover='page' />}>
      <Switch>
        <Route
          path={`${AUTH_PREFIX_PATH}/login`}
          component={lazy(() => import(`./authentication/login`))}
        />

        <Route
          path={`${AUTH_PREFIX_PATH}/register`}
          component={lazy(() => import(`./authentication/register`))}
        />

        <Route
          path={`${AUTH_PREFIX_PATH}/forgot-password`}
          component={lazy(() => import(`./authentication/forgot-password`))}
        />

        <Route
          exact
          path={`${APP_PREFIX_PATH}/resend-activation-link`}
          component={lazy(() =>
            import(`./authentication/verify-user/resend-link`)
          )}
        />

        <Route
          path={`${AUTH_PREFIX_PATH}/resend-activation-link`}
          component={lazy(() =>
            import(`./authentication/verify-user/resend-link`)
          )}
        />

        <Route
          path={`${AUTH_PREFIX_PATH}/activate/:signup_token`}
          component={lazy(() => import(`./authentication/verify-user`))}
        />

        <Route
          path={`${AUTH_PREFIX_PATH}/verify-subuser/:user_id`}
          component={lazy(() => import(`./authentication/verify-sub-user`))}
        />

        <Route
          path={`${AUTH_PREFIX_PATH}/error`}
          component={lazy(() => import(`./errors/error-page-2`))}
        />

        <Redirect
          from={`${AUTH_PREFIX_PATH}`}
          to={`${AUTH_PREFIX_PATH}/login`}
        />
      </Switch>
    </Suspense>
  );
};

export default AppViews;
