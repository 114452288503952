import { combineReducers } from 'redux';
import Auth from './Auth';
import Theme from './Theme';
import Client from './Client';
import Location from './Location';
import SmsCounter from './SmsCounter';

const reducers = combineReducers({
  theme: Theme,
  auth: Auth,
  client: Client,
  location: Location,
  smsCounter: SmsCounter,
});

export default reducers;
