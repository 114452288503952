import {
  SIDE_NAV_LIGHT,
  NAV_TYPE_SIDE,
  DIR_LTR,
} from 'constants/ThemeConstant';
import { env } from './EnvironmentConfig';

export const APP_NAME = 'SentientiQ';
export const APP_POWERED_BY = 'realdrseattle | Allure Esthetic';
export const SOCKET_URL = env.API_ENDPOINT_URL;
export const API_BASE_URL = `${env.API_ENDPOINT_URL}/api`;
export const APP_PREFIX_PATH = '';
export const AUTH_PREFIX_PATH = '/auth';
export const USER_TIMEOUT = 900;

export const THEME_CONFIG = {
  navCollapsed: true,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: 'en',
  navType: NAV_TYPE_SIDE,
  topNavColor: '#3e82f7',
  headerNavColor: '',
  mobileNav: false,
  currentTheme: 'light',
  direction: DIR_LTR,
};
