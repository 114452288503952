import fetch from 'auth/FetchInterceptor';

const ClientService = {};

ClientService.uniqueUserName = async function (data) {
  return await fetch({
    url: '/client/unique-user',
    method: 'post',
    headers: {
      'public-request': 'true',
      'Content-Type': 'application/json',
    },
    data: data,
  })
    .then((response) => response)
    .catch((err) => err);
};

ClientService.getFirstLoginDetails = async function (data) {
  return await fetch({
    url: '/client/get-first-login-details',
    method: 'post',
    headers: {
      'public-request': 'true',
      'Content-Type': 'application/json',
    },
    data: data,
  })
    .then((response) => response)
    .catch((err) => err);
};

ClientService.saveBusinessDetails = async function (data) {
  return await fetch({
    url: '/client/save-business-details',
    method: 'post',
    headers: {
      'public-request': 'false',
      'Content-Type': 'application/json',
    },
    data: data,
  })
    .then((response) => response)
    .catch((err) => err);
};

ClientService.googleAccessToken = async function (data) {
  return await fetch({
    url: '/google/access-token',
    method: 'get',
    mode: 'no-cors',
    headers: {
      'public-request': 'false',
      'Content-Type': 'application/json',
    },
    data: data,
  })
    .then((response) => response)
    .catch((err) => err);
};

export default ClientService;
